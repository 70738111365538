$(function () {

    $('.title-list').hover(function () {
        if ($(this).children('a').hasClass('disable')) {
            return;
        }
        $(this).children('.inner-menu').slideToggle(100);
        $(this).children('.img-accordionStatus').addClass('is-active');
        if ($(this).hasClass('choco')) {
            $('.horizon').css('background', '#ff9bba');
        } else if ($(this).hasClass('job')) {
            $('.horizon').css('background', '#1f497d');
        }  else if ($(this).hasClass('town-night')) {
            $('.horizon').css('background', '#ff687c');
        } else if ($(this).hasClass('kokyu')) {
            $('.horizon').css('background', '#191919');
        } else if ($(this).hasClass('town')) {
            $('.horizon').css('background', '#f7a52a');
        }
        $('.horizon').animate({
            width: 1000
        }, 500);
    }, function () {
        if ($(this).children('a').hasClass('disable')) {
            return;
        }
        $('.inner-menu').slideUp(100);
        $(this).children('.img-accordionStatus').removeClass('is-active');
        $('.horizon').animate({
            width: 0
        }, 500);
    });

    (function ($) {
        $.fn.scrollHeight = function () {
            scrollHeight = 0;
            $(window).scroll(function () {
                if ($(this).scrollTop() > 0) {
                    scrollHeight = $(this).scrollTop();
                } else {
                    scrollHeight = 0;
                }
            });
        }
    })(jQuery);
    $(this).scrollHeight();

    (function () {
        $('.js-toTop').on('click', function () {
            var position = $('body,html').offset().top;
            animate(position);
        });

        function animate(position) {
            $('body,html').animate({
                scrollTop: position
            }, 500, 'swing');
            return false;
        }
    })();
});
